<template>
  <v-container
    id="regular-tables"
    tag="section"
  >
    <base-v-component
      heading="Orders"
      link="components/simple-tables"
    />
    <div>
      <base-material-card
        icon="mdi-truck"
        :title="$t('shippingCompanies') | capitalize"
        class="px-5 py-3"
      >
        <v-data-table
          dense
          :headers="headers"
          :loading="loading"
          :items="companies"
          item-key="id"
          class="pt-8 "
          loading-text="Loading... Please wait"
          hide-default-footer
          disable-sort
          disable-pagination
        >
          <template v-slot:item.action="{ item }">
            <div class="my-2">
              <v-btn
                class="primary"
                small
                @click="companySelected = item"
                v-text="$t('edit')"
              />
            </div>
          </template>
          <template v-slot:footer>
            <v-row class="mt-2 py-4 px-2 justify-end">
              <v-btn
                class="primary"
                @click="showCreateCompany = true"
                v-text="$t('create')"
              />
            </v-row>
          </template>
        </v-data-table>
      </base-material-card>
    </div>
    <shipping-company-create
      v-if="showCreateCompany"
      @shippingCompanyCreated="handleShippingCompanyCreated"
      @cancel="showCreateCompany = false"
    />
    <shipping-company-update
      v-if="companySelected"
      :shipping-company="companySelected"
      @shippingCompanyCreated="handleShippingCompanyCreated"
      @cancel="companySelected = null"
      @shippingCompanyUpdated="handleShippingCompanyUpdated"
    />
  </v-container>
</template>

<script>
import ShippingCompaniesRepository from '@/api/ShippingCompaniesRepository'
import ShippingCompanyCreate from '@/views/dashboard/components/ShippingCompanies/ShippingCompanyCreate'
import ShippingCompanyUpdate from '@/views/dashboard/components/ShippingCompanies/ShippingCompanyUpdate'
import { capitalize } from '@/helpers/helpers'

export default {
  name: 'ShippingCompaniesIndex',
  components: { ShippingCompanyUpdate, ShippingCompanyCreate },
  data () {
    return {
      companies: [],
      headers: [
        { text: capitalize(this.$t('name')), value: 'name' },
        { text: capitalize(this.$t('actions')), value: 'action', sortable: false, align: 'end' },
      ],
      loading: true,
      showCreateCompany: false,
      companySelected: null,
    }
  },
  created () {
    this.getCompanies()
  },
  methods: {
    getCompanies () {
      return new Promise((resolve) => {
        ShippingCompaniesRepository.index()
          .then(({ data }) => {
            this.companies = data.data
            this.loading = false
            resolve()
          })
      })
    },
    handleShippingCompanyUpdated () {
      this.getCompanies()
    },
    handleShippingCompanyCreated (shippingCompany) {
      this.companies.push(shippingCompany)
      this.showCreateCompany = false
    },
  },
}
</script>

<style scoped>

</style>

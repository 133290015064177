<template>
  <v-dialog
    persistent
    :value="true"
    width="600"
  >
    <v-card>
      <v-card-title>
        {{ $t('edit') | capitalize }} <span class="font-weight-bold">{{ shippingCompany.name | capitalize }}  </span>
      </v-card-title>
      <v-card-text>
        <shipping-company-form
          v-model="modified"
          :loading="requesting"
          :errors="errors"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="grey"
          :loading="requesting"
          @click="$emit('cancel')"
        >
          {{ $t('back') | capitalize }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="requesting"
          @click="submit"
        >
          {{ $t('save') | capitalize }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ShippingCompaniesRepository from '@/api/ShippingCompaniesRepository'
import ShippingCompanyForm from '@/views/dashboard/components/ShippingCompanies/ShippingCompanyForm'
import clone from 'clone'

export default {
  name: 'ShippingCompanyUpdate',
  components: { ShippingCompanyForm },
  props: {
    shippingCompany: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      modified: {
        name: '',
      },
      requesting: false,
      errors: {
        name: null,
      },
    }
  },
  created () {
    this.modified = clone(this.shippingCompany)
  },
  methods: {
    submit () {
      this.cleanErrors()
      this.requesting = true
      ShippingCompaniesRepository.update(this.shippingCompany.id, {
        name: this.modified.name,
      })
        .then(({ data }) => {
          this.$emit('shippingCompanyUpdated', data)
          this.requesting = false
        })
        .catch(({ response }) => {
          this.requesting = false
          if (response.status === 422) {
            const { data } = response
            Object.keys(data.errors).forEach(key => {
              this.errors[key] = data.errors[key]
            })
          }
        })
    },
    cleanErrors () {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = null
      })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <v-dialog
    persistent
    :value="true"
    width="600"
  >
    <v-card>
      <v-card-title>
        {{ $t('c.title') }}
      </v-card-title>
      <v-card-text>
        <shipping-company-form
          v-model="shippingCompany"
          :loading="requesting"
          :errors="errors"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="grey"
          :loading="requesting"
          @click="$emit('cancel')"
        >
          {{ $t('back') }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="requesting"
          @click="submit"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ShippingCompaniesRepository from '@/api/ShippingCompaniesRepository'
import ShippingCompanyForm from '@/views/dashboard/components/ShippingCompanies/ShippingCompanyForm'

export default {
  name: 'ShippingCompanyCreate',
  components: { ShippingCompanyForm },
  data () {
    return {
      shippingCompany: {
        name: '',
      },
      requesting: false,
      errors: {
        name: null,
      },
    }
  },
  methods: {
    submit () {
      this.cleanErrors()
      this.requesting = true
      ShippingCompaniesRepository.store(this.shippingCompany)
        .then(({ data }) => {
          this.$emit('shippingCompanyCreated', data)
        })
        .catch(({ response }) => {
          this.requesting = false
          if (response.status === 422) {
            const { data } = response
            Object.keys(data.errors).forEach(key => {
              this.errors[key] = data.errors[key]
            })
          }
        })
    },
    cleanErrors () {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = null
      })
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          title: 'Create Shipping Company',
        },
      },
      it: {
        c: {
          title: 'Creazione di una compagnia di spedizioni',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <v-form>
    <v-container class="py-0">
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="value.name"
            :label="$t('name')"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.name : null"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'ShippingCompanyForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
